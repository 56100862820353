<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="save()"
        >
          <v-row>
            <!-- Sexo -->
            <v-col cols="6" class="py-0">
              <v-autocomplete
                v-model="generoSelected"
                :items="generos"
                label="Sexo (*)"
                :rules="rules.required"
                item-text="value"
                item-value="value"
                outlined
                dense
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="6" class="py-0">
              <currency-input
                label="Importe (*)"
                v-model="importe"
                oulined
                dense="false"
                outlined="false"
                :options="currencyOptions"
                :rules="importe > 0 ? [] : rules.required"
              >
              </currency-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="py-0">
              <v-text-field
                v-model="edadDesde"
                label="Edad desde (*)"
                dense
                outlined
                v-mask="'###'"
                :rules="
                  edadDesde === 0
                    ? []
                    : rules.required.concat([
                        rules.validDateRange(
                          parseInt(edadDesde),
                          parseInt(edadHasta)
                        )
                      ])
                "
              ></v-text-field>
            </v-col>

            <v-col cols="6" class="py-0">
              <v-text-field
                v-model="edadHasta"
                label="Edad hasta (*)"
                dense
                outlined
                v-mask="'###'"
                :rules="
                  edadHasta === 0
                    ? []
                    : rules.required.concat([
                        rules.validDateRange(
                          parseInt(edadDesde),
                          parseInt(edadHasta)
                        )
                      ])
                "
              ></v-text-field>
            </v-col>
          </v-row>

          <!-- Fecha desde -->
          <v-row>
            <v-col cols="6" class="py-0">
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fechaDesdeSelected"
                    label="Fecha desde (*)"
                    :append-icon="calendarIcon"
                    v-bind="attrs"
                    autocomplete="not"
                    hint="Formato DD/MM/AAAA"
                    v-mask="'##/##/####'"
                    onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                    @blur="fechaDesde = parseDateToIso(fechaDesdeSelected)"
                    outlined
                    dense
                    v-on="on"
                    :rules="
                      rules.required.concat(
                        new Date(parseDateToIso(fechaDesdeSelected)) <=
                          new Date(parseDateToIso(fechaHastaSelected))
                      ) || 'Formato incorrecto'
                    "
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaDesde"
                  no-title
                  @change="fechaDesdeSelected = formatDate(fechaDesde)"
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <!-- Fecha hasta -->
            <v-col cols="6" class="py-0">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fechaHastaSelected"
                    label="Fecha hasta (*)"
                    :append-icon="calendarIcon"
                    hint="Formato DD/MM/AAAA"
                    v-mask="'##/##/####'"
                    @blur="fechaHasta = parseDateToIso(fechaHastaSelected)"
                    outlined
                    onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                    autocomplete="not"
                    dense
                    v-bind="attrs"
                    v-on="on"
                    :rules="
                      rules.required.concat(
                        new Date(parseDateToIso(fechaDesdeSelected)) <=
                          new Date(parseDateToIso(fechaHastaSelected))
                      ) || 'Formato incorrecto'
                    "
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaHasta"
                  no-title
                  @change="fechaHastaSelected = formatDate(fechaHasta)"
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions class="py-0">
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";

export default {
  name: "EditValoresCapitaAnsesDesempleo",
  directives: { mask },
  components: { CurrencyInput },
  props: {
    editValCapitaAnsesDesempleo: { type: Object }
  },
  data: () => ({
    isFormValid: false,
    formEditTitle: enums.titles.EDITAR_VALOR_CAPITA_ANSES_DESEMPLEO,
    newTitle: enums.titles.NUEVO_VALOR_CAPITA_ANSES_DESEMPLEO,
    calendarIcon: enums.icons.CALENDAR,
    rules: rules,
    importe: 0,
    edadDesde: null,
    edadHasta: null,
    fechaHastaSelected: null,
    fechaDesdeSelected: null,
    menu1: false,
    menu2: false,
    fechaDesde: null,
    fechaHasta: null,
    generoSelected: null,
    generos: [{ value: "M" }, { value: "F" }],
    currencyOptions: {
      locale: "nl-NL",
      currency: "USD",
      currencyDisplay: "narrowSymbol",
      valueRange: {
        min: 0,
        max: 999999999999999
      },
      precision: undefined,
      hideCurrencySymbolOnFocus: true,
      hideGroupingSeparatorOnFocus: true,
      hideNegligibleDecimalDigitsOnFocus: true,
      autoDecimalDigits: false,
      exportValueAsInteger: false,
      autoSign: true,
      useGrouping: true
    },
    topeRemunId: null
  }),
  created() {
    if (this.editValCapitaAnsesDesempleo) {
      this.setValoresCapitaAnsesDesempleo();
    } else {
      this.newValoresCapitaAnsesDesempleo();
    }
  },
  methods: {
    ...mapActions({
      saveValorCapitaANSESDesempleo:
        "configuracion/saveValorCapitaANSESDesempleo",
      setAlert: "user/setAlert"
    }),

    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    async setValoresCapitaAnsesDesempleo() {
      this.montoCapitaId = this.editValCapitaAnsesDesempleo.montoCapitaId;
      this.importe = this.editValCapitaAnsesDesempleo.importe;
      this.edadDesde = this.editValCapitaAnsesDesempleo.edadDesde;
      this.edadHasta = this.editValCapitaAnsesDesempleo.edadHasta;
      this.fechaDesde = this.parseDateToIso(
        this.editValCapitaAnsesDesempleo.fechaDesde
      );
      this.fechaHasta = this.parseDateToIso(
        this.editValCapitaAnsesDesempleo.fechaHasta
      );
      this.fechaDesdeSelected = this.editValCapitaAnsesDesempleo.fechaDesde;
      this.fechaHastaSelected = this.editValCapitaAnsesDesempleo.fechaHasta;
      this.generoSelected = this.editValCapitaAnsesDesempleo.sexo;
    },
    async newValoresCapitaAnsesDesempleo() {
      this.formEditTitle = this.newTitle;
    },
    async save() {
      const data = {
        montoCapitaId: this.editValCapitaAnsesDesempleo?.montoCapitaId,
        importe: this.importe,
        edadDesde: this.edadDesde,
        edadHasta: this.edadHasta,
        fechaDesde: this.parseDateToIso(this.fechaDesdeSelected),
        fechaHasta: this.parseDateToIso(this.fechaHastaSelected),
        sexo: this.generoSelected
      };
      const res = await this.saveValorCapitaANSESDesempleo(data);
      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito." });
        this.closeModal();
      }
    },
    closeModal() {
      this.$emit("closeAndReload");
    }
  }
};
</script>
